<template>
  <div class="NavBar">
    <div class="left_title">
      梓齐の网站
    </div>
    <div class="right_control">
      <div class="btn" :class="{ isChooes: value === item.type }" v-for="item in cardList" :key="item.key" @click="btnClick(item)">
        <el-icon v-if="item.icon">
          <component :is="item.icon" />
        </el-icon>
        <span v-show="device === 'desktop'">{{ item.text }}</span>
      </div>
      <div class="login">
        <el-dropdown v-show="isLogin" class="dropdown" @command="handleCommand">
          <div class="profile_photo">
            <el-avatar :size="30" :src="avatar" />
            <span v-show="device === 'desktop'" class="name">{{ userName }}</span>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <span v-show="!isLogin" @click="login"> 登录 </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import store from '@/store';
import { ref, computed } from '@vue/reactivity';

const cardList = window.getConfig('buttonList')

const props = defineProps({
  value: String
})

const device = computed(() => store.getters.device)

const userInfo = JSON.parse(localStorage.getItem('userInfo'))

const isLogin = ref(!!userInfo)

let userName = userInfo?.realname

let avatar = userInfo?.photo

const emit = defineEmits(['input', 'change'])
function btnClick(item) {
  emit('input', item.type)
  emit('change', item)
}
function login() {
  window.location.href = window.getConfig('loginAddress') + window.location.href
}
function handleCommand(command) {
  switch (command) {
    case 'logout':
      localStorage.removeItem('userInfo')
      isLogin.value = false
      break
    default:
      break
  }
}
</script>

<style lang="scss" scoped>
.NavBar {
  position: absolute;
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: space-between;
  line-height: 60px;
}
.left_title {
  color: #fff;
  font-size: 22px;
  padding-left: 25px;
  user-select: none;
}
.right_control {
  display: flex;
  .btn {
    margin: 0 10px;
    color: #fff;
    user-select: none;
    cursor: pointer;
    :deep(.el-icon) {
      vertical-align:middle;
      padding-right: 5px;
    }
  }
  .isChooes {
    color: #40a9ff;
  }
  .login {
    color: #fff;
    user-select: none;
    cursor: pointer;
    margin: 0 25px;
    .dropdown {
      align-items: center;
      height: 100%;
    }
    .profile_photo {
      color: #fff;
      display: flex;
      align-items: center;
      height: 100%;
      .name {
        padding-left: 5px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .left_title {
    font-size: 16px;
  }
  .right_control {
    .btn {
      margin: 0 5px;
    }
    .login {
      margin: 0 25px 0 10px;
      font-size: 14px;
    }
  }
}
</style>
