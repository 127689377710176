<template>
  <div class="star">
    <el-row class="content" align="middle">
      <template v-for="col in list" :key="col.path">
        <el-col :span="6">
          <div class="website" @click="toPage(col)">
            <el-image class="icon" :src="col.img" fit="fit" />
            <div class="name">{{col.name}}</div>
          </div>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script setup>
// const { ref } = require("vue-demi");
const list = window.getConfig('recommendList')
const toPage = (col) => {
  window.open(col.path)
}
</script>

<style lang="scss" scoped>
.star {
  width: 60%;
  height: 100%;
  margin-left: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(5px);
}
.website {
  user-select: none;
  cursor: pointer;
  padding: 20px;
  // background-color: rgba(255, 255, 255, 0.1);
  .icon {
    width: 50px;
    height: 50px;
  }
  .name {
    padding: 10px 0 0px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:hover {
    .name {
      color: #40a9ff;
    }
  }
}
</style>
